<template>
  <div>
    <not-found></not-found>
  </div>
</template>

<script>
import NotFound from '@/components/404';
import DefaultHeadMixin from '@/mixins/defaultHead';

export default {
  mixins: [DefaultHeadMixin],
  components: { NotFound },
};
</script>

<style></style>
